$sidenav-width: 280px;

.navbar_toggle {
    @apply w-[26px] cursor-pointer py-2 z-50 flex flex-wrap;

    span {
        @apply delay-500 basis-[22px] min-w-[22px] h-[2px] mb-[4px] bg-white;
    }

    span:nth-child(2) {
        @apply basis-[12px] min-w-[12px] w-[12px];
    }

    span:last-child {
        @apply mb-0 basis-[17px] min-w-[17x] w-[17px];
    }
}


.overlay {
    @apply duration-[350ms] fixed w-full h-full top-0 left-0 opacity-0 z-50 invisible bg-overlay-bg;

    &.nav_active {
        @apply opacity-100 visible;
    }
}

.sidenav_wrapper {
    @apply duration-[350ms] fixed top-0 h-full overflow-hidden z-50 bg-white;
    width: $sidenav-width;
    right: -$sidenav-width;

    .close_button{
        @apply absolute top-2 right-2;
    }

    &.nav_active {
        @apply right-0;
    }

    .inner{
        @apply mx-4 my-8;
        .item{
            @apply leading-6 text-sm text-slate-700 mb-2 border-b pb-2;
        }
    }
}
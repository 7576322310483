.headline {
    @apply bg-[#333] text-white text-sm py-1;

    .headline_inner {
        @apply container mx-auto flex justify-between;
    }
}

.desktop_header {
    @apply items-center;

    nav {
        @apply container mx-auto flex py-8 border-b;
    }
    .menu_wrapper {
        @apply bg-primary_color-0 py-2 min-h-[36px];
        .menu {
            @apply text-white container flex mx-auto;
            a {
                @apply mr-3 font-medium text-sm;
            }
        }
    }
}

.wrapper {
    @apply w-full top-0 z-20 sticky mx-auto bg-primary_color-0 shadow-sm;
    transition: height 0.2s;

    .inner {
        @apply flex justify-center items-center py-1 relative;
    }

    .search_wrap {
        @apply w-full inline-block py-2;
        transition: width 0.2s;

        .search_input {
            @apply py-1 px-3 leading-8 bg-[#F2F2F2] mx-2;
        }
    }

    .cart_icon {
        @apply absolute right-[56px] top-3;
        svg{
            @apply stroke-1 w-6 h-6;
        }
    }

    .toggle {
        @apply top-2 absolute right-[12px]
    }

    &.top_fix {
        @apply h-[54px] mb-5;

        .search_wrap {
            @apply p-0 w-[calc(100%-84px)];
        }

        .branch_link {
            @apply -mt-[42px] opacity-0;
        }
    }
}

.normal_nav_wrapper {
    @apply flex transition-none bg-white h-12 items-center px-2 py-3 sticky top-0 z-20 text-slate-700 lg:hidden;
    h1 {
        @apply flex-1 text-center pr-10;
    }
}

.invert_nav{
    span {
        @apply bg-slate-500
    }
}

.hit_image {
    @apply block;
}

.product_name {
    @apply text-xs text-slate-600 mb-1 ;
}

.product_price {
    @apply text-xs text-primary_color-0;
}

.product_sale_price {
    @apply text-xs ml-2 line-through text-slate-500;
}

.hit_wrapper {
    @apply lg:max-h-[450px] w-[515px] z-50 drop-shadow absolute overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100;
}

.search_input_wrapper {
    @apply basis-[50%] ml-10 bg-slate-100 rounded-lg;

    .search_input {
        @apply text-sm relative;
        input {
            @apply px-8 py-2 w-full  bg-transparent;
        }
    }
}

.m_search_wrapper {
    @apply flex grow items-center w-full bg-white px-2 rounded-md;
    .m_search_input {
        @apply w-full border-0 leading-10 bg-transparent relative;
    }
}

.keyword {
    @apply px-4 py-2 border-b;
    .title{
        @apply text-slate-400
    }
}

.item_hover:hover {
    @apply bg-slate-100;
}
.wrapper {
    // @apply mt-5 mb-16;
    .title {
        @apply my-3 justify-center text-center lg:text-left text-lg;
        h2 {
            @apply border-b border-slate-300 px-8 pb-1;
        }
    }

    .items {
        @apply grid grid-cols-2 md:grid-cols-5 gap-1 md:gap-x-6 sm:grid-cols-4;
        box-sizing: border-box;
    }

    .item {
        @apply flex-col flex pb-4;
        // @apply md:border md:overflow-hidden;
    }

    .info {
        @apply flex flex-col mt-2 px-2 leading-5;
        .link {
            @apply text-sm font-body font-thin no-underline;
        }
        .p_price {
            @apply text-sm text-red-600;
        }
        .p_image {
            @apply p-10;
        }
        .p_brand{
            @apply text-sm font-normal;
        }
    }

    .view_all {
        @apply text-center mb-8;
        .link {
            @apply text-sm bg-secondary_color hover:bg-primary_color-0 text-[#222222] px-4 py-2 rounded-2xl ;
        }
    }
}

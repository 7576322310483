.wrapper {
    @apply lg:relative lg:ml-auto;
    .icon {
        @apply  lg:text-slate-600 relative block text-white;
        svg {
            @apply h-8 w-8 stroke-1;
        }
    }

    .number {
        @apply lg:bg-primary_color-0 lg:text-white absolute -right-[3px] -top-[3px] rounded-full bg-white w-4 h-4 p-0 m-0 text-slate-500 font-mono text-xs leading-tight text-center;
    }
}

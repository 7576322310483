.item {
    @apply text-white justify-center flex items-center text-[11px] md:h-10 after:content-[''] basis-1/2 ;

    a{
        @apply flex text-center flex-col md:flex-row items-center  px-2 h-full;
        svg {
            @apply mb-1;
        }
    }

}

.contact_info{
    @apply flex text-white py-5 text-sm flex-col md:flex-row justify-between pb-10;

    h3{
        @apply font-medium
    }
}